"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VOLTAGE_DIVIDER_V3 = exports.VOLTAGE_DIVIDER_V2 = exports.V3 = exports.V2 = exports.V1 = exports.POWER_DIVIDER_V3 = exports.POWER_DIVIDER_V2 = exports.CURRENT_DIVIDER_V3 = exports.CURRENT_DIVIDER_V2 = exports.AMBIENT_TEMPERATURE_DIVIDER_V3 = exports.AMBIENT_TEMPERATURE_DIVIDER_V2 = void 0;
exports.getDividers = getDividers;
exports.mapGetStatusResponse = mapGetStatusResponse;
exports.mapResponseToState = mapResponseToState;
exports.mapSetStateToDevice = mapSetStateToDevice;
exports.mapStandardResponse = mapStandardResponse;
var _deviceController = require("@mevris/device-controller");
// tslint:disable: radix

var AMBIENT_TEMPERATURE_DIVIDER_V2 = exports.AMBIENT_TEMPERATURE_DIVIDER_V2 = 10;
var AMBIENT_TEMPERATURE_DIVIDER_V3 = exports.AMBIENT_TEMPERATURE_DIVIDER_V3 = 1;
var CURRENT_DIVIDER_V2 = exports.CURRENT_DIVIDER_V2 = 100000;
var CURRENT_DIVIDER_V3 = exports.CURRENT_DIVIDER_V3 = 1000;
var POWER_DIVIDER_V2 = exports.POWER_DIVIDER_V2 = 100000;
var POWER_DIVIDER_V3 = exports.POWER_DIVIDER_V3 = 1;
var VOLTAGE_DIVIDER_V2 = exports.VOLTAGE_DIVIDER_V2 = 10000;
var VOLTAGE_DIVIDER_V3 = exports.VOLTAGE_DIVIDER_V3 = 1;

/**
 * Device version
 */
var V1 = exports.V1 = 1;
var V2 = exports.V2 = 2;
var V3 = exports.V3 = 3;

/**
 * Device state in device api format
 */

/**
 * Device State in Mevris Platform format
 */

/**
 * Returns dividers based on device version
 * @param version
 */
function getDividers(version) {
  if (version !== V3) {
    return {
      ambientTemperature: AMBIENT_TEMPERATURE_DIVIDER_V2,
      current: CURRENT_DIVIDER_V2,
      power: POWER_DIVIDER_V2,
      voltage: VOLTAGE_DIVIDER_V2
    };
  }
  return {
    ambientTemperature: AMBIENT_TEMPERATURE_DIVIDER_V3,
    current: CURRENT_DIVIDER_V3,
    power: POWER_DIVIDER_V3,
    voltage: VOLTAGE_DIVIDER_V3
  };
}

/**
 * Converts device response current to number
 * @param current
 * @param version device version
 */
function responseToAmbientTemperature(ambientTemperature, version) {
  var dividers = getDividers(version);
  var num = parseInt(ambientTemperature) / dividers.ambientTemperature;
  return Math.round(num * 100) / 100;
}

/**
 * Converts device response current to number
 * @param current
 * @param version device version
 */
function responseToCurrent(current, version) {
  var dividers = getDividers(version);
  var num = parseInt(current) / dividers.current;
  return Math.round(num * 100) / 100;
}

/**
 * Converts device response voltage to number
 * @param voltage
 * @param version device version
 */
function responseToVoltage(voltage, version) {
  var dividers = getDividers(version);
  var num = parseInt(voltage) / dividers.voltage;
  return Math.round(num);
}

/**
 * Converts device response power to number
 * @param power
 * @param version device version
 */
function responseToPower(power, version) {
  var dividers = getDividers(version);
  var num = parseInt(power) / dividers.power;
  return Math.round(num);
}

/**
 * Converts a device response to stateMap object
 * @param response response from device api
 * @param version device version
 */
function mapResponseToState(response) {
  var version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : V3;
  var stateMap = {
    temperature: responseToAmbientTemperature(response.ambientTemperature, version),
    online: response.connected === 'MQTT_CONNECTED - the cient is connected' || response.connected === 1,
    current: responseToCurrent(response.current, version),
    power: responseToPower(response.power, version),
    on: !(response.standby === '1'),
    'thermostat.setpoint': parseInt(response.thermostatSetpoint),
    voltage: responseToVoltage(response.voltage, version),
    'fanSpeed.mode': response.thermostatFanMode,
    'thermostat.mode': response.thermostatMode,
    'wifi.ssid': response.SSID
  };
  return stateMap;
}
function mapStandardResponse(status, response) {
  var resp = {};
  if (status === _deviceController.RESPONSE_STATUS_SUCCESS) {
    resp.data = response;
  } else if (status === _deviceController.RESPONSE_STATUS_ERROR) {
    resp.error = new Error(response);
  }
  resp.status = status;
  return resp;
}
function mapGetStatusResponse(response) {
  response.connected = !!response.connected;
  return response;
}
var commandMapper = {
  'HorizontalAirFlowDirection:switch': {
    key: 'horizontalAirFlowDirection'
  },
  'VerticalAirFlowDirection:switch': {
    key: 'verticalAirFlowDirection'
  },
  'fan-speed': {
    key: 'thermostatFanMode'
  },
  "switch": {
    key: 'standby'
  },
  'thermostat-mode': {
    key: 'thermostatMode'
  },
  'thermostat-setpoint': {
    key: 'thermostatSetpoint'
  }
};
function mapSetStateToDevice(stateMap) {
  var command = {};
  if ('on' in stateMap && !stateMap.on) {
    return {
      name: 'setStandby',
      params: {
        standby: true
      }
    };
  }
  for (var _i = 0, _Object$keys = Object.keys(stateMap); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];
    if (commandMapper[key]) {
      command[commandMapper[key].key] = stateMap[key];
    }
  }
  return {
    name: 'setBulk',
    params: command
  };
}